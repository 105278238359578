<template>
  <div class="container space-1">
    <div class="rounded overflow-hidden space-top-2 space-top-lg-0 px-3 px-md-8 pr-lg-0 shadow" style="background-color:#EAF4FD">
      <div class="row justify-content-lg-between align-items-lg-center no-gutters">
        <div class="col-lg-6 space-lg-1 text-center text-lg-left">
          <h2 class="mb-3">API Security and Zero Trust Approach</h2>
          <p class="mb-4">API Security is essential in today's digital world, where API communication enables a variety of applications and services that are critical to our modern lives. API Security was once considered a luxury, but now it's an absolute necessity. API security protocols such as API Key Management and API throttling can be the difference between life and death for both consumers and businesses.</p>
          <a id="ga-OW02-05-03" href="https://blog.openapihub.com/en-us/api-security-and-zero-trust-approach/?_gl=1*13ctr1a*_gcl_au*OTAyNDU1OTUxLjE2OTQ0MDAwNDA." class="btn btn-primary transition-3d-hover" target="_blank">Learn More about API Security</a>
        </div>
        <div class="col-lg-6 space-top-1 ml-auto">
          <div class="w-80 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/trust/trust-cta.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CTA'
}
</script>

<style lang="scss" scoped>
</style>
