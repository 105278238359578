<template>
  <div>
    <Hero />
    <HowItWorks />
    <Showcases />
    <CTA />
    <MoreFeatures />
    <BnCTA />
    <OahOverview class="space-top-2" />
  </div>
</template>

<script>
import Hero from './components/hero'
import HowItWorks from './components/how-it-works'
import Showcases from './components/showcases'
import CTA from './components/cta'
import MoreFeatures from './components/more-features'
import BnCTA from './components/bn-cta'
import OahOverview from '@/components/oah-overview'

export default {
  name: 'APITrust',
  components: {
    Hero,
    HowItWorks,
    Showcases,
    CTA,
    MoreFeatures,
    BnCTA,
    OahOverview
  },
  metaInfo() {
    return {
      title: 'FabriXAPI',
      titleTemplate: 'Trust & Security | %s',
      link: [
        { rel: 'canonical', href: 'https://www.fabrixapi.com/trust-certification' }
      ],
      meta: [
        { name: 'description', content: 'FabriXAPI platform is trusted by enterprises from different sectors. The SaaS API platform is certified by ISO 27001:2013 Information Security and reviewed by AWS as APN Technology Partner (Software Path).' },
        { property: 'og:site_name', content: 'FabriXAPI' },
        { property: 'og:title', content: 'Trust & Security | FabriXAPI' },
        { property: 'og:description', content: 'FabriXAPI platform is trusted by enterprises from different sectors. The SaaS API platform is certified by ISO 27001:2013 Information Security and reviewed by AWS as APN Technology Partner (Software Path).' },
        { property: 'og:image', content: 'https://www.fabrixapi.com/assets/img/thumbnails/fabrixapi-thumbnail.jpg' },
        { property: 'og:url', content: 'https://www.fabrixapi.com/trust-certification' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
