<template>
  <div id="how-it-works" class="position-relative overflow-hidden">
    <div class="container space-2">
      <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">CERTIFICATIONS AND RECOGNITIONS</span>
        <h2>Trusted by Clients Across Different Sectors</h2>
        <p>FabriXAPI is an award-winning SaaS API platform trusted by hundreds of API providers in different scales and sectors.</p>
      </div>
      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-1.svg">
          </figure>
          <h3 class="mb-3">Certified by ISO 27001:2013 Information Security</h3>
          <p>FabriXAPI is specialized in cloud API management and is certified with ISO 27001:2013 in the provision of API platform services, consisting of API Service Subscription Management, API Provider, Subscriber & Services Onboarding and Offboarding, etc.</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/trust/trust-feature-1.svg" alt="Certified by ISO 27001:2013 Information Security">
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0 order-md-2">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-2.svg">
          </figure>
          <h3 class="mb-3">Qualified Software by AWS</h3>
          <p>FabriXAPI team (beNovelty) is AWS APN Technology Partner. The FabriXAPI platform has passed the AWS FTR review and considered as "Qualified Software" by AWS.</p>
        </div>
        <div class="col-12 col-md-6 order-md-1">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/trust/trust-feature-2.svg" alt="Qualified Software by AWS">
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-3.svg">
          </figure>
          <h3 class="mb-3">Trusted by Leading Enterprises</h3>
          <p>FabriXAPI team (beNovelty) is trusted by leading enterprises including statutory body, banks, retail group, F&B group & tech ecosystem, etc.</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/trust/trust-feature-3.svg" alt="Trusted by Leading Enterprises">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWorks'
}
</script>

<style lang="scss" scoped>
</style>
