<template>
  <div id="more-features">
    <div class="container space-2">

      <div class="w-md-85 w-lg-65 text-center mx-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">MORE TO DISCOVER</span>
        <h2>Want to Unlock More API Portal Features?</h2>
        <p>FabriXAPI is a managed cloud API platform helping companies to build API Projects with ease. As a registered FabriXAPI member, you will be able to enjoy more features and customize your API Developer Portal.</p>
        <a class="btn btn-primary transition-3d-hover my-3" href="https://provider-portal.fabrixapi.com/" target="_blank">Start for Free <i class="far fa-arrow-right ml-1" /></a>
      </div>

      <div class="row">
        <div v-for="(item, index) in Features" :key="index" class="col-md-4 mb-4">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-3">
              <img class="img-fluid" :src="require(`@/assets/svg/icons/${item.icon}`)">
            </figure>
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>

      <!-- Book a Demo -->
      <div class="container space-2">
        <div class="card border-0 shadow" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-hero-1.svg`)})`}">
          <div class="card-body text-center py-5">
            <h2>Want a Tour of FabriXAPI?</h2>
            <p>Contact our team to book a demo and get started with your API Journey!</p>
            <router-link
              :to="{ path: `/contact`}"
              class="btn btn-primary mt-3"
            >Contact Us
            </router-link>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'MoreFeatures',
  data() {
    return {
      Features: [
        {
          title: 'Portal Access Control',
          description: 'Built-in API Portal Access Control for Private, Partner or Public APIs',
          icon: 'icon-20.svg'
        },
        {
          title: 'API Product Management',
          description: 'Manage your API Collections with flexible options',
          icon: 'icon-28.svg'
        },
        {
          title: 'Subscription Management',
          description: 'Pre-built API Subscription Management for Quality of Services (QoS)',
          icon: 'icon-73.svg'
        },
        {
          title: 'API Partner Management',
          description: 'Leverage the built-in Partner API management features to invite specific partner\'s developer to consume your partner APIs',
          icon: 'icon-69.svg'
        },
        {
          title: 'API Monetization',
          description: 'Monetize your partner or public APIs with built-in API monetization features',
          icon: 'icon-5.svg'
        },
        {
          title: 'Administrative CI/CD Pipeline',
          description: 'APIOps integration GitHub/Gitlab via CI/CD Pipeline',
          icon: 'icon-3.svg'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
